import * as React from "react"
import Layout from "../../../components/Layout"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import ImageDownload from "../../../components/ImageDownload"



const IndexPage = () => {
    const root = "/img/download/my-success-story"

    return (
        <Layout pageTitle="My Success Story - Templates">
            <Row>
                <Col>
                    <Link to="/members/my-success-story">Back</Link>
                </Col>
            </Row>
                
            <ImageDownload
            root ="/img/download/my-success-story"
            filename="ive-joined"
            name="Membership Announcement Template"
            text="Use this template to shout about your membership! You can use the Brand Message copy below. Pay particular attention to the My Success Story section and talk about why you joined and what Women's Busines Club means to you!"
            text2=""
            squareHref="https://www.canva.com/design/DAFqfpHvpSM/EwjKsUwtj67w3gGjFj6PXQ/view?utm_content=DAFqfpHvpSM&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
            horizontalHref="https://www.canva.com/design/DAFYHLFlf4c/zFYAdnxORMWJqu1KCf1Frg/view?utm_content=DAFYHLFlf4c&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
            copyHref="https://docs.google.com/document/d/1N17wids4-a-HimzOmu_KfNRug_ea1WvzupO_9SvW3Uk/edit?usp=sharing"
            />  
           
           

            
        </Layout>

        
    )
}

export default IndexPage
